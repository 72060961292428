import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import * as Utils from './utils'

export function ApgSeal() {
  const { apgSealScriptId } = Utils.Configuration.useConfiguration()

  React.useEffect(() => {
    window.APG && window.APG.init()
  }, [])

  if (!apgSealScriptId) {
    return null
  }

  return (
    <React.Fragment>
      <Helmet>
        <script
          defer="defer"
          type="text/javascript"
          src={`https://${apgSealScriptId}.snippet.antillephone.com/apg-seal.js`}
        />
      </Helmet>
      <div
        id="apg-seal-container"
        data-apg-seal-id={apgSealScriptId}
        data-apg-image-size="32"
        data-apg-image-type="basic-small"
      />
    </React.Fragment>
  )
}
